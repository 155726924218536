import React, { useEffect, memo } from 'react'
import { useDrag } from '@use-gesture/react'
import { useSpring, animated } from '@react-spring/web'
import { clamp } from 'lodash'
import { useMeasure } from 'react-use'

import { useApp } from 'store/AppStore'

import { Root, Slides } from './ProductSlider.styled.js'
import Slide from './Slide'

function areEqual(prev, next) {
  return (
    prev.isPortrait === next.isPortrait &&
    prev.isShort === next.isShort &&
    prev.selectedProductIndex === next.selectedProductIndex &&
    prev.style.transform === next.style.transform
  )
}

const ProductSlider = memo(
  ({
    style,
    products,
    selectedProductIndex,
    setSelectedProduct,
    isShort,
    isPortrait,
    altLayout,
  }) => {
    const [ref, { width }] = useMeasure()

    const [{ x, cursor }, api] = useSpring(() => ({
      x: selectedProductIndex * width,
      cursor: 'grab',
    }))

    const bind = useDrag(
      ({ dragging, movement: [mx], swipe: [swipeX], cancel }) => {
        if (isPortrait) {
          if (swipeX) {
            // console.log(swipeX)
            const nextSlideIndex = clamp(selectedProductIndex - swipeX, 0, products.length - 1)
            console.log(nextSlideIndex)
            setSelectedProduct(nextSlideIndex)
            // cancel()
          }
        } else {
          if (dragging) {
            // console.log('dragging')
            if (Math.abs(mx) > width / 2) {
              const nextSlideIndex = clamp(
                selectedProductIndex - Math.sign(mx),
                0,
                products.length - 1
              )
              setSelectedProduct(nextSlideIndex)
              api.start({ x: -(nextSlideIndex * width - mx), cursor: 'grab' })
              cancel()
            } else {
              api.start({ x: -(selectedProductIndex * width - mx), cursor: 'grabbing' })
            }
          } else {
            api.start({ x: -(selectedProductIndex * width), cursor: 'grab' })
          }
        }
      },
      { axis: 'x' }
    )

    useEffect(() => {
      api.start({ x: -(selectedProductIndex * width) })
    }, [selectedProductIndex, width, api])

    return (
      <Root
        style={style}
        isShort={isShort}
        isPortrait={isPortrait}
        altLayout={altLayout}
      >
        <Slides
          ref={ref}
          {...bind()}
          style={{ x, cursor }}
        >
          {products.map((product, id) => (
            <Slide
              key={product.id}
              {...product}
              selected={selectedProductIndex === id}
              visible={Math.abs(selectedProductIndex - id) < 2}
            ></Slide>
          ))}
        </Slides>
      </Root>
    )
  },
  areEqual
)

const WrappedSlider = (props) => {
  const { products, selectedProductIndex, setSelectedProduct, isShort, isPortrait, altLayout } =
    useApp()
  return (
    <ProductSlider
      {...props}
      {...{ products, selectedProductIndex, setSelectedProduct, isShort, isPortrait, altLayout }}
    ></ProductSlider>
  )
}

export default animated(WrappedSlider)
