const data = [
	{
		name: 'Electrically Heated Cigarette Smoking System',
		abbr: 'escss',
		description: `The EHCSS consisted of a specially designed cigarette containing tobacco rolled into a tobacco mat, which was wrapped over with a cigarette paper. This special cigarette was inserted into a battery-powered heater equipped with an array of eight blades that heated the tobacco and minimized burning during puffing.`,
		products: [
			{
				id: 1,
				releaseYear: '1998',
				devYears: '1990-98',
				name: 'Accord',
				model: 'accord_1',
				image: 'accord_1',
				png_seq: 'accord_1',
				spritesheet: 'device_1',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `90's`,
					icon: 'www',
					text: 'Tim Berners-Lee invents hypertext.',
				},
				features: [
					{
						title: 'About this device',
						blocks: [
							{
								text: `A specially designed cigarette was inserted into a battery-powered heater equipped with an array of eight blades that heated the tobacco and minimized burning during puffing.`,
							},
						],
					},
					{
						title: 'User experience',
						blocks: [
							{
								text:
									'A flow sensor initiated the heating circle for each of the blades as the puff was detected.',
								icon: 'icn-flowsensor',
							},
							{
								text: 'Experience limited to 8 puffs per cigarette.',
								icon: 'icn-times8',
							},
						],
					},
					// {
					// 	title: 'Heating technology',
					// 	blocks: [
					// 		{
					// 			subtitle: `External Heating`,
					// 			text: `A specifically design cigarette containing conventional tobacco rolled into a tobacco mat was wrapped over with cigarette paper. This special cigarette was inserted into a battery-powered heater equipped with an array of eight blades.`,
					// 			icon: 'icn-externalheating',
					// 		},
					// 		{
					// 			text: `The heater was equipped with an array of eight blades made from an iron-aluminide alloy, one blade for each of the eight possible puffs per cigarette.`,
					// 			icon: 'icn-times8',
					// 		},

					// 		{
					// 			text: `With this design, the tobacco reached a peak temperature of approximately 500 ºC. `,
					// 			icon: 'icn-500c',
					// 		},
					// 	],
					// },
				],
			},
			{
				id: 2,
				releaseYear: '2002',
				devYears: 'Mid 1990s',
				name: 'Accord',
				model: 'accord_2',
				image: 'accord_2',
				png_seq: 'accord_2',
				spritesheet: 'device_2',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `90's`,
					icon: 'www',
					text: 'Tim Berners-Lee invents hypertext.',
				},
				features: [
					{
						title: 'About this device',
						blocks: [
							{
								text: `A specially designed cigarette was inserted into a battery-powered heater equipped with an array of eight blades that heated the tobacco and minimized burning during puffing.`,
							},
						],
					},
					{
						title: 'User experience',
						blocks: [
							{
								text:
									'A flow sensor initiated the heating circle for each of the blades as the puff was detected.',
								icon: 'icn-flowsensor',
							},
							{
								text: 'Experience limited to 8 puffs per cigarette.',
								icon: 'icn-times8',
							},
						],
					},
					{
						title: 'Heating technology',
						blocks: [
							{
								subtitle: `External Heating`,
								text: `A specifically design cigarette containing conventional tobacco rolled into a tobacco mat was wrapped over with cigarette paper. This special cigarette was inserted into a battery-powered heater equipped with an array of eight blades.`,
								icon: 'icn-externalheating',
							},
							{
								text: `The heater was equipped with an array of eight blades made from an iron-aluminide alloy, one blade for each of the eight possible puffs per cigarette.`,
								icon: 'icn-times8',
							},

							{
								text: `With this design, the tobacco reached a peak temperature of approximately 500 ºC. `,
								icon: 'icn-500c',
							},
						],
					},
				],
			},
			{
				id: 3,
				releaseYear: '2006',
				devYears: '2000-04',
				name: 'Heatbar',
				model: 'heatbar',
				image: 'heatbar',
				png_seq: 'heatbar',
				spritesheet: 'device_3',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `00's`,
					icon: 'msg',
					text: 'Web 2.0 technology morphs into what will be known as social media.',
				},
				features: [
					{
						title: 'About this device',
						blocks: [
							{
								text: `The EHCSS Series K was more sensitive in puff detection and activation, and included a superior rechargeable battery.\n\nModifications to the cigarette such as a highly activated carbon filter were introduced.`,
							},
						],
					},
					{
						title: 'User experience',
						blocks: [
							{
								text:
									'A flow sensor initiated the heating circle for each of the blades as the puff was detected.',
								icon: 'icn-flowsensor',
							},
							{
								text:
									'Flavor, filtration, airflow and heat control improved to further reduce formation of undesirable pyrolysis and combustion products.',
								icon: 'icn-airflow',
							},
							{
								text: 'Increased quantity of aerosol delivered due to a bigger blade.',
								icon: 'icn-sizeincrease',
							},
							{
								text: 'Experience limited to 8 puffs per cigarette.',
								icon: 'icn-times8',
							},
						],
					},
					{
						title: 'Heating technology',
						blocks: [
							{
								subtitle: `External Heating`,
								text: `A specifically designed cigarette containing conventional tobacco rolled into a tobacco mat was wrapped over with cigarette paper. This special cigarette was inserted into a battery-powered heater equipped with an array of eight blades.`,
								icon: 'icn-externalheating',
							},
							{
								text: `The heater was equipped with an array of eight blades made from an iron-aluminide alloy, one blade for each of the eight possible puffs per cigarette.`,
								icon: 'icn-times8',
							},
							{
								text: `With this design, the tobacco reached a peak temperature of approximately 500 ºC. `,
								icon: 'icn-500c',
							},
						],
					},
					// {
					//  title: 'Battery',
					//  centerTitle: true,
					//  blocks: [
					//   {
					//    image: `device_3`,
					//   },
					//  ],
					// },
				],
			},
		],
	},
	{
		name: 'tobacco heating system',
		abbr: 'ths',
		description: `The tobacco heating system (THS) is a product specially designed to heat a tobacco stick without burning it. This is achieved by heating the tobacco stick from the inside towards the outside of the stick.`,
		products: [
			{
				id: 4,
				releaseYear: '2009',
				devYears: '2007-09',
				name: 'THS 2.0',
				model: 'ths_20',
				image: 'ths_20',
				png_seq: 'ths_20',
				spritesheet: 'device_4',
				frame: {
					width: 350,
					height: 560,
				},
				dyk: {
					year: `00's`,
					icon: 'msg',
					text: 'Web 2.0 technology morphs into what will be known as social media.',
				},
				features: [
					{
						title: 'About this device',
						blocks: [
							{
								text: `This was the first two-piece lab prototype with internal tobacco stick heating system and temperature control of the heating blade.`,
							},
						],
					},
					{
						title: 'User experience',
						blocks: [
							{
								text:
									'Introduction of two-piece system, a holder and a charger. Significant holder size reduction.',
								icon: 'icn-size',
							},
							{
								subtitle: 'Revised tobacco stick:',
								text: `Shredded cast leaf tobacco processing. New filter element.`,
								icon: 'icn-tobaccostick',
							},
							{
								text: 'Experience limited to 6 minutes (continuous heating).',
								icon: 'icn-6min',
							},
						],
					},
					{
						title: 'Heating technology',
						blocks: [
							{
								subtitle: `Internal Heating`,
								text: `Tobacco is heated from the core. The Heat Control Technology™ monitors the tobacco temperature to ensure consistent taste and experience.`,
								icon: 'icn-internalheating',
							},
							{
								text: `The heating blade measured __3 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `Peak tobacco temperature during use reduced to below 400° C`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Battery',
						blocks: [
							{
								subtitle: 'Introduction of a new battery for the charger.',
								text: `Sufficient for usage of one tobacco stick before needing recharging in the charger.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Battery lifetime',
								text: `Up to approx. __5000 sessions__`,
								icon: 'icn-batterylifetime',
							},
						],
					},
				],
			},
			{
				id: 5,
				releaseYear: '2011',
				devYears: '2009-11',
				name: 'THS 2.1',
				model: 'ths_21',
				image: 'ths_21',
				png_seq: 'ths_21',
				spritesheet: 'device_5',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'tablet',
					text:
						'Tablet computers become fasted adopted technology hardware in history.',
				},
				features: [
					{
						title: 'About this device',
						blocks: [
							{
								text: `This was the first industrial design prototype developed for consumer research.`,
							},
						],
					},
					{
						title: 'User experience',
						blocks: [
							{
								text:
									'1st industrial design prototype for consumer research. Improved consistency of sensory experience.',
								icon: 'icn-sensoryxp',
							},
							{
								text: `From now onwards tobacco plug manufacturing: crimped tobacco.`,
								icon: 'icn-crimpedtobacco',
							},
							{
								text: 'Experience limited to 6 minutes (continuous heating).',
								icon: 'icn-6min',
							},
						],
					},
					{
						title: 'Heating technology',
						blocks: [
							{
								subtitle: `Internal Heating`,
								text: `Tobacco is heated from the core. The Heat Control Technology™ monitors the tobacco temperature to ensure consistent taste and experience.`,
								icon: 'icn-internalheating',
							},
							{
								text: `The heating blade measured __3 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `Peak tobacco temperature during use reduced to below 400° C`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Battery',
						blocks: [
							{
								subtitle: 'Introduction of a new battery for the charger.',
								text: `Sufficient for usage of one tobacco stick before needing recharging in the charger.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Battery lifetime',
								text: `Up to approx. __5000 sessions__`,
								icon: 'icn-batterylifetime',
							},
						],
					},
				],
			},
			{
				id: 6,
				releaseYear: '2013',
				devYears: '2011-13',
				name: 'IQOS 2.2',
				model: 'iqos_22',
				image: 'iqos_22',
				png_seq: 'iqos_22',
				spritesheet: 'device_6',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'tablet',
					text:
						'Tablet computers become fasted adopted technology hardware in history.',
				},
				features: [
					{
						title: 'About this device',
						blocks: [
							{
								text: `A new industrial design for commercialization has been introduced.\n\nA new heating blade design has been introduced, used from this version onwards.`,
							},
						],
					},
					{
						title: 'User experience',
						blocks: [
							{
								text: 'Improved consistency of sensory experience.',
								icon: 'icn-sensoryxp',
							},
							{
								text: `Introduction of the cleaning kit.`,
								icon: 'icn-cleaningkit',
							},
							{
								text:
									'Consistent puffing ensured and usage target to __6 minutes__ or __14 puffs__.',
								icon: 'icn-6min-14puff',
							},
						],
					},
					{
						title: 'Heating technology',
						blocks: [
							{
								subtitle: `Internal Heating`,
								text: `Tobacco is heated from the core. The Heat Control Technology™ monitors the tobacco temperature to ensure consistent taste and experience.`,
								icon: 'icn-internalheating',
							},
							{
								text: `The heating blade measured __5 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `Peak tobacco temperature during use reduced to below 400° C`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Battery',
						blocks: [
							{
								subtitle: 'Introduction of a new battery for the charger.',
								text: `Sufficient for usage of one tobacco stick before needing recharging in the charger.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Battery lifetime',
								text: `Up to approx. __7300 sessions__`,
								icon: 'icn-batterylifetime',
							},
						],
					},
				],
			},
			{
				id: 7,
				releaseYear: '2014',
				devYears: '2014-15',
				name: 'IQOS 2.4',
				model: 'iqos_24',
				image: 'iqos_24',
				png_seq: 'iqos_24',
				spritesheet: 'device_7',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'tablet',
					text:
						'Tablet computers become fasted adopted technology hardware in history.',
				},
				features: [
					{
						title: 'About this device',
						blocks: [
							{
								text: `The device ergonomics has been improved and the design has been developed for a large-scale manufacturing.`,
							},
						],
					},
					{
						title: 'User experience',
						blocks: [
							{
								text:
									'Ergonomics improved and product designed for a large-scale manufacturing.',
								icon: 'icn-size',
							},
							{
								text: `Introduction of the cleaning kit.`,
								icon: 'icn-cleaningkit',
							},
							{
								text:
									'Consistent puffing ensured and usage target to __6 minutes__ or __14 puffs__.',
								icon: 'icn-6min-14puff',
							},
						],
					},
					{
						title: 'Heating technology',
						blocks: [
							{
								subtitle: `Internal Heating`,
								text: `Tobacco is heated from the core. The Heat Control Technology™ monitors the tobacco temperature to ensure consistent taste and experience.`,
								icon: 'icn-internalheating',
							},
							{
								text: `The heating blade measured __5 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `Peak tobacco temperature during use reduced to below 400° C`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Battery',
						blocks: [
							{
								subtitle: 'Introduction of a new battery for the charger.',
								text: `Sufficient for usage of one tobacco stick before needing recharging in the charger.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Battery lifetime',
								text: `Up to approx. __7300 sessions__`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Battery charging time',
								text: `Holder: 4:10min\nCharger: 90min`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Battery capacity',
								text: `2900 mAh nominal`,
								icon: 'icn-batterycapacity',
							},
						],
					},
				],
			},
			{
				id: 11,
				releaseYear: '2019',
				devYears: '2019',
				name: 'IQOS 3 DUO',
				model: 'iqos_3_duo',
				image: 'iqos_3_duo',
				png_seq: 'iqos_3_duo',
				spritesheet: 'device_8',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'cloud',
					text:
						'The emergence of Amazon Web Services (AWS) ushers in cloud computing revolution.',
				},
				features: [
					{
						title: 'About this device',
						blocks: [
							{
								text: `The ergonomics and design have been changed with the introduction of a buttonless side opening.`,
							},
						],
					},
					{
						title: 'User experience',
						blocks: [
							{
								text: 'Compact and ergonomic design.',
								icon: 'icn-size',
							},
							{
								text: `Near-field Communication to access web for easy registration.`,
								icon: 'icn-nearfield',
							},
							{
								text: 'Connectivity: Bluetooth, passive.',
								icon: 'icn-bluetooth',
							},
							{
								text: 'Double LED indicator.',
								icon: 'icn-doubleled',
							},
							{
								subtitle: 'Holder-Charger',
								text: 'Experiences without recharging.',
								icon: 'icn-2-20-times',
							},
						],
					},
					{
						title: 'Heating technology',
						blocks: [
							{
								subtitle: `Internal Heating`,
								text: `Tobacco is heated from the core. The Heat Control Technology™ monitors the tobacco temperature to ensure consistent taste and experience.`,
								icon: 'icn-internalheating',
							},
							{
								text: `The heating blade measured __5 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `Peak tobacco temperature during use reduced to below 400° C`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Battery',
						blocks: [
							{
								subtitle: 'Introduction of a new battery for the charger.',
								text: `Battery has increased charging current and more capacity to allow for 2 consecutive experiences.`,
								icon: 'icn-times2',
							},
							{
								subtitle: 'Battery lifetime',
								text: `Up to approx. __14600 sessions__`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Battery charging time',
								text: `Holder:\n Av. 1:50min, 0-1 use\nAv. 3:25min, 1-2 use\nAv. 5:15min, 0-2 use\n\nCharger:\n20min.`,
								icon: 'icn-batterychargingtime',
							},
							{
								subtitle: 'Battery capacity',
								text: `2900 mAh nominal`,
								icon: 'icn-batterycapacity',
							},
						],
					},
				],
			},
			{
				id: 12,
				releaseYear: '2021',
				devYears: '2020',
				name: 'ILUMA PRIME',
				model: 'iluma_prime',
				image: 'iluma_prime',
				png_seq: 'iluma_prime',
				spritesheet: 'device_9',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'cloud',
					text:
						'The emergence of Amazon Web Services (AWS) ushers in cloud computing revolution.',
				},
				features: [
					{
						title: 'About this device',
						blocks: [
							{
								text: `No cleaning needed after use as there is no tobacco residue.\n\nInduction technology is also used in IQOS ILUMA ONE allowing up to 20 experiences on a full charge.`,
							},
						],
					},
					{
						title: 'User experience',
						blocks: [
							{
								text: 'New design with a wrap around flap.',
								icon: 'icn-size',
							},
							{
								subtitle: `Auto-start`,
								text: `Tobacco stick detected when inserted and device automatically turns on.`,
								// icon: 'icn-nearfield',
							},
							{
								text:
									'Accelerometer tells when the device is used or not and turns the device off if needed. “Haptic feedback” tells how long is left of the tobacco experience.',
								// icon: 'icn-bluetooth',
							},
						],
					},
					{
						title: 'Heating technology',
						blocks: [
							{
								subtitle: `Smart Core Induction system`,
								text: `Tobacco plug made of the cast leaf tobacco material with a susceptor incorporated in the center of the plug.`,
								// icon: 'icn-internalheating',
							},
							{
								text: `Bladeless device. Innovative metal heating elements of approximately 4x12mm placed inside the tobacco stick.`,
								// icon: 'icn-blade',
							},
						],
					},
					{
						title: 'Battery',
						blocks: [
							{
								subtitle: 'Battery lifetime',
								text: `up to __14600 sessions__.`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Charging time:',
								text: `Av. 1:50 min, 0-1 use \n Av. 3:25 min, 1-2 use \n Av. 5:15 min, 0-2 use\n Av. 10:05 min, full charge first time`,
								icon: 'icn-batterychargingtime',
							},
							{
								subtitle: 'Battery capacity',
								text: `2900 mAh nominal`,
								icon: 'icn-batterycapacity',
							},
						],
					},
				],
			},
		],
	},
]

export default data
