import styled, { css } from 'styled-components/macro'

import { colors } from 'styled/theme.styled'

const numShapes = 7
const numDots = 78

const nthShapes = new Array(numShapes).fill('').map((_, id) => id + 1)
const nthDots = new Array(numDots).fill('').map((_, id) => id + 1)

export const Root = styled.div`
  pointer-events: none;
  /* padding: ${({ isShort }) => (isShort ? 12 : 40)}px; */
  z-index: 99;

  svg {
    display: block;

    ${({ altLayout }) =>
      altLayout &&
      css`
        width: 44px;
        height: 44px;
      `};
    > #shapes {
      * {
        opacity: ${({ show }) => (show ? 1 : 0)};
        fill: ${({ variant }) => colors[variant]};
        transition: all 0.3s;

        ${nthShapes.map(
          (nth) => css`
            &:nth-child(${nth}) {
              transition-delay: ${500 + nth * 100}ms;
            }
          `
        )};
      }
    }

    > #circle {
      * {
        opacity: ${({ show }) => (show ? 1 : 0)};
        fill: ${({ variant }) => colors[variant]};
        transition: all 0.3s;

        ${nthDots.map(
          (nth) => css`
            &:nth-child(${nth}) {
              transition-delay: ${800 + nth * 10}ms;
            }
          `
        )};
      }
    }

    > #text {
      opacity: ${({ show, variant }) => (show && variant === 'white' ? 1 : 0)};
      transform: translate(0, ${({ show }) => (show ? 0 : 100)}px);
      transition: all 0.5s;
      transition-delay: 1200ms;
    }
  }
`
