export const colors = {
  white: '#ffffff',
  backgroundLight: '#FDFBFA',
  darkBlue: '#94A6E7',
  lightBlue: '#256382',
  faintBlue: '#e7eef1',
  midGrey: '#707070',
  lightGrey: '#d6d8d8',
  neutralsGrey: 'rgba(94, 115, 180, 0.20)',
  midGrey_025: 'rgba(112,112,112,0.25)',
  purple_700: '#445382',
  purple_600: '#5E73B4',
  purple_600_a50: 'rgba(94,115,180,0.5)',
  purple_500: '#94A6E7',
  purple_400: '#DCE4FF',
  purple_300: '#F1F4FF',
  purple_300_a50: 'rgba(241,244,255,0.5)',
  graphite: '#2A2B2E',
  grey: '#696D6F',
  beige_400: '#F7F1ED',
  beige_300: '#FAF5F1',
  beige_200: '#FBF8F5',
  beige_100: '#FDFBFA',
  white_st80: '#FFFFCC',
}
