import React, { useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'rc-scrollbars'
import { useTransition, animated } from '@react-spring/web'
import Markdown from 'react-markdown'
import { useApp } from 'store/AppStore'

import { ReactComponent as PlusIcon } from 'assets/uiIcons/plus.svg'
import { ReactComponent as MinusIcon } from 'assets/uiIcons/minus.svg'
import { ReactComponent as ChevronIcon } from 'assets/uiIcons/chevron-down.svg'

import { icons, images } from 'assets/features'

import {
	Body,
	FeatureBlock,
	FeatureItem,
	Head,
	ProductFeaturesRoot,
	Root,
} from './FeatureBox.styled.js'
import Collapsible from 'components/Collapsible/index.jsx'
import { usePrevious } from 'react-use'

const languages = {
	en: {
		knowMore: 'Know more',
		collapse: 'Collapse',
	},
	fr: {
		knowMore: 'En savoir plus',
		collapse: 'Réduire',
	},
	de: {
		knowMore: 'Mehr erfahren',
		collapse: 'Einklappen',
	},
}
const ProductFeatures = ({ product, selected }) => {
	const { altLayout } = useApp()

	const [chevronRotate, setChevronRotate] = useState(true)
	const scrollbarsRef = useRef()

	useEffect(() => {
		if (selected) {
			scrollbarsRef.current.scrollToTop()
		}
	}, [selected])

	const handleUpdate = (values) => {
		const { scrollTop, scrollHeight, clientHeight } = values
		const rotate = scrollHeight - clientHeight - scrollTop > 10

		if (rotate !== chevronRotate) {
			setChevronRotate(rotate)
		}
	}

	return (
		<ProductFeaturesRoot chevronRotate={chevronRotate}>
			<Scrollbars ref={scrollbarsRef} onUpdate={handleUpdate}>
				<div className='Inner'>
					{product.features.map(({ title, blocks, centerTitle }, id) => (
						<FeatureItem key={id} centerTitle={centerTitle}>
							<div className='Title'>{title}</div>
							{blocks.map(({ text, subtitle, icon, image }, blockId) => (
								<FeatureBlock key={blockId} hasIcon={!!icon} altLayout={altLayout}>
									{image && (
										<div className='Image'>
											<img src={images[image]} alt='' />
										</div>
									)}
									{icon && <div className='Icon'>{icons[icon]}</div>}
									{text && (
										<div className='Text'>
											{subtitle && <div className='Subtitle'>{subtitle}</div>}
											<Markdown>{text}</Markdown>
										</div>
									)}
								</FeatureBlock>
							))}
						</FeatureItem>
					))}
				</div>
			</Scrollbars>
			<div className='Footer'>
				<div className='Icon'>
					<ChevronIcon></ChevronIcon>
				</div>
			</div>
		</ProductFeaturesRoot>
	)
}
const FeatureBox = () => {
	const {
		lang,
		products,
		selectedProductIndex,
		showFeatures,
		toggleFeatures,
		isShort,
		isPortrait,
		altLayout,
	} = useApp()
	const previousIndex = usePrevious(selectedProductIndex)

	const headTransition = useTransition(showFeatures, {
		from: { transform: `translate(0, ${showFeatures ? 100 : -100}%)` },
		enter: { transform: `translate(0, 0%)` },
		leave: { transform: `translate(0, ${showFeatures ? -100 : 100}%)` },
	})

	const bodyDir = Math.sign(selectedProductIndex - previousIndex || 0)
	const bodyTransition = useTransition(selectedProductIndex, {
		from: { transform: `translate(${100 * bodyDir}%, 0)` },
		enter: { transform: `translate(0%, 0)` },
		leave: { transform: `translate(${-100 * bodyDir}%, 0)` },
	})

	return (
		<Root isShort={isShort} isPortrait={isPortrait} altLayout={altLayout}>
			<Head onClick={() => toggleFeatures()}>
				{headTransition((props, item) =>
					item ? (
						<animated.div className='Inner' style={props}>
							<span>{languages[lang].collapse}</span>
							<div className='Icon'>
								<MinusIcon></MinusIcon>
							</div>
						</animated.div>
					) : (
						<animated.div className='Inner' style={props}>
							<span>{languages[lang].knowMore}</span>
							<div className='Icon'>
								<PlusIcon></PlusIcon>
							</div>
						</animated.div>
					)
				)}
			</Head>
			<Collapsible collapsed={!showFeatures}>
				<Body isShort={isShort} altLayout={altLayout}>
					{bodyTransition((props, item) => (
						<animated.div className='Inner' style={props}>
							<ProductFeatures product={products[item]}></ProductFeatures>
						</animated.div>
					))}
				</Body>
			</Collapsible>
		</Root>
	)
}

export default FeatureBox
