import styled, { css } from 'styled-components/macro'
import { animated } from '@react-spring/web'

import { colors } from 'styled/theme.styled'

export const Root = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	--sidebar-width: 40%;
	--sidebar-width: min(400px, 40%);

	> .Inner {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-rows: auto 1fr auto;
		transition: all, 0.3s;
		filter: blur(${({ blur }) => (blur ? 8 : 0)}px);

		> * {
			position: relative;
		}

		> .Body {
			${({ altLayout }) =>
				altLayout &&
				css`
					margin-top: -72px;
					width: 60%;
					width: calc(100% - var(--sidebar-width));
				`};
		}
	}

	.Loader {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${colors.darkBlue};
	}
`

export const Header = styled(animated.div)`
	width: 100%;
	height: ${({ $altLayout }) => ($altLayout ? 72 : 110)}px;
	flex: 0 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 12px;
`

export const Footer = styled(animated.div)`
	width: 100%;
	flex: 0 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 40px;

	@media only screen and (max-height: 950px) {
		padding-bottom: ${({ $altLayout }) => ($altLayout ? 0 : 20)}px;
	}
`
