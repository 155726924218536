const data = [
	{
		name: 'Elektrisch beheiztes System für Zigaretten',
		abbr: 'escss',
		description: `Das EHCSS bestand aus einer speziell entwickelten Zigarette, die Tabak enthielt, der in einem Tabak-"Teppich" gerollt und in Zigarettenpapier eingewickelt war. Diese spezielle Zigarette wurde in ein batteriebetriebenes Heizgerät gesteckt, das mit einem Netz aus acht Klingen ausgestattet war, die den Tabak erhitzten und die Verbrennungen während des Zugs minimierten.`,
		products: [
			{
				id: 1,
				releaseYear: '1998',
				devYears: '1990-98',
				name: 'Accord',
				model: 'accord_1',
				image: 'accord_1',
				png_seq: 'accord_1',
				spritesheet: 'device_1',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `90's`,
					icon: 'www',
					text: 'Tim Berners-Lee invents hypertext.',
				},
				features: [
					{
						title: 'Über dieses Gerät',
						blocks: [
							{
								text: `Eine speziell entwickelte Zigarette wurde in ein batteriebetriebenes Erhitzungsgerät mit einer Reihe von acht Heizblättern gesteckt, die den Tabak erhitzten und das Verbrennen während des Ziehens minimierten.`,
							},
						],
					},
					{
						title: 'Nutzererlebnis',
						blocks: [
							{
								text:
									'Ein Durchflusssensor initiierte den Heizkreislauf für jedes der Heizblätter, sobald das Ziehen erkannt wurde.',
								icon: 'icn-flowsensor',
							},
							{
								text: 'Das Erlebnis ist auf 8 Züge pro Zigarette begrenzt.',
								icon: 'icn-times8',
							},
						],
					},
				],
			},
			{
				id: 2,
				releaseYear: '2002',
				devYears: 'Mitte 1990er',
				name: 'Accord',
				model: 'accord_2',
				image: 'accord_2',
				png_seq: 'accord_2',
				spritesheet: 'device_2',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `90's`,
					icon: 'www',
					text: 'Tim Berners-Lee invents hypertext.',
				},
				features: [
					{
						title: 'Über dieses Gerät',
						blocks: [
							{
								text: `Eine speziell entwickelte Zigarette wurde in ein batteriebetriebenes Erhitzungsgerät mit einer Reihe von acht Heizblättern gesteckt, die den Tabak erhitzten und das Verbrennen während des Ziehens minimierten.`,
							},
						],
					},
					{
						title: 'Nutzererlebnis',
						blocks: [
							{
								text:
									'Ein Durchflusssensor initiierte den Heizkreislauf für jedes der Heizblätter, sobald das Ziehen erkannt wurde.',
								icon: 'icn-flowsensor',
							},
							{
								text: 'Das Erlebnis ist auf 8 Züge pro Zigarette begrenzt.',
								icon: 'icn-times8',
							},
						],
					},
					{
						title: 'Erhitzungstechnologie',
						blocks: [
							{
								subtitle: `Erhitzung von Aussen`,
								text: `Eine speziell entwickelte Zigarette mit herkömmlichem Tabak, der in eine Tabakmatte gerollt wurde, wurde mit Zigarettenpapier umwickelt. Diese spezielle Zigarette wurde in ein batteriebetriebenes Erhitzungsgerät mit einer Reihe von acht Heizblättern gesteckt.`,
								icon: 'icn-externalheating',
							},
							{
								text: `Das Erhitzungsgerät war mit einer Reihe von acht Heizblättern aus einer Eisen-Aluminid-Legierung ausgestattet, ein Heizblatt für jeden der acht möglichen Züge pro Zigarette.`,
								icon: 'icn-times8',
							},

							{
								text: `Mit dieser Konstruktion erreichte der Tabak eine Spitzentemperatur von etwa 500 ºC.`,
								icon: 'icn-500c',
							},
						],
					},
				],
			},
			{
				id: 3,
				releaseYear: '2006',
				devYears: '2000-04',
				name: 'Heatbar',
				model: 'heatbar',
				image: 'heatbar',
				png_seq: 'heatbar',
				spritesheet: 'device_3',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `00's`,
					icon: 'msg',
					text: 'Web 2.0 technology morphs into what will be known as social media.',
				},
				features: [
					{
						title: 'Über dieses Gerät',
						blocks: [
							{
								text: `Die EHCSS-Serie K war empfindlicher bei der Erkennung und Aktivierung von Zügen und verfügte über einen besseren wiederaufladbaren Akku.\n\nEs wurden Änderungen an der Zigarette vorgenommen, wie z. B. die Einführung eines Aktivkohlefilters.`,
							},
						],
					},
					{
						title: 'Nutzererlebnis',
						blocks: [
							{
								text:
									'Ein Durchflusssensor initiierte den Heizkreislauf für jedes einzelne Heizblatt, sobald der Zug erkannt wurde.',
								icon: 'icn-flowsensor',
							},
							{
								text:
									'Geschmack, Filterung, Luftstrom und Wärmeregulierung wurden verbessert, um die Bildung von unerwünschten Pyrolyse- und Verbrennungsprodukten weiter zu reduzieren.',
								icon: 'icn-airflow',
							},
							{
								text: 'Erhöhte Aerosolmenge dank einer grösseren Klinge.',
								icon: 'icn-sizeincrease',
							},
							{
								text: 'Das Erlebnis ist auf 8 Züge pro Zigarette begrenzt.',
								icon: 'icn-times8',
							},
						],
					},
					{
						title: 'Erhitzungstechnologie',
						blocks: [
							{
								subtitle: `Erhitzung von Aussen`,
								text: `Eine speziell entwickelte Zigarette mit herkömmlichem Tabak, der in eine Tabakmatte gerollt wurde, wurde mit Zigarettenpapier umwickelt. Diese spezielle Zigarette wurde in ein batteriebetriebenes Erhitzungsgerät mit einer Reihe von acht Heizblättern gesteckt.`,
								icon: 'icn-externalheating',
							},
							{
								text: `Das Erhitzungsgerät war mit einer Reihe von acht Heizblättern aus einer Eisen-Aluminid-Legierung ausgestattet, ein Heizblatt für jeden der acht möglichen Züge pro Zigarette.`,
								icon: 'icn-times8',
							},
							{
								text: `Mit dieser Konstruktion erreichte der Tabak eine Spitzentemperatur von etwa 500 ºC.`,
								icon: 'icn-500c',
							},
						],
					},
				],
			},
		],
	},
	{
		name: 'Tabakerhitzer System',
		abbr: 'ths',
		description: `Das Tabakerhitzungssystem (THS auf Englisch) ist ein Produkt. das speziell dafür entwickelt wurde, einen Tabakstick zu erwärmen, ohne ihn zu verbrennen. Dies wird erreicht, indem der Tabakstick von der Innenseite zur Außenseite des Sticks hin erhitzt wird.`,
		products: [
			{
				id: 4,
				releaseYear: '2009',
				devYears: '2007-09',
				name: 'THS 2.0',
				model: 'ths_20',
				image: 'ths_20',
				png_seq: 'ths_20',
				spritesheet: 'device_4',
				frame: {
					width: 350,
					height: 560,
				},
				dyk: {
					year: `00's`,
					icon: 'msg',
					text: 'Web 2.0 technology morphs into what will be known as social media.',
				},
				features: [
					{
						title: 'Über dieses Gerät',
						blocks: [
							{
								text: `Dies war der erste zweiteilige Laborprototyp mit internem Tabakstangenheizsystem und Temperaturregelung des Heizblatts.`,
							},
						],
					},
					{
						title: 'Nutzererlebnis',
						blocks: [
							{
								text:
									'Einführung eines zweiteiligen Systems, eines Halters und eines Ladegeräts. Erhebliche Verkleinerung des Halters.',
								icon: 'icn-size',
							},
							{
								subtitle: 'Überarbeitete Tabaksticks:',
								text: `Verarbeitung von zerkleinertem Cast-Leaf. Neues Filterelement.`,
								icon: 'icn-tobaccostick',
							},
							{
								text:
									'Das Erlebnis ist auf 6 Minuten begrenzt (kontinuierliches erhitzen).',
								icon: 'icn-6min',
							},
						],
					},
					{
						title: 'Erhitzungstechnologie',
						blocks: [
							{
								subtitle: `Erhitzung von Innen`,
								text: `Der Tabak wird vom Kern her erhitzt. Die Heat Control Technology™ überwacht die Temperatur des Tabaks, um einen gleichbleibenden Geschmack und ein gleichbleibendes Erlebnis zu gewährleisten.`,
								icon: 'icn-internalheating',
							},
							{
								text: `Das Heizblatt misst __3 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `Die Spitzentemperatur des Tabaks während des Gebrauchs wird auf unter 400° C reduziert.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: 'Einführung eines neuen Akkus für das Ladegerät.',
								text: `Ausreichend für das Benützen eines Tabaksticks, bevor der Halter im Ladegerät wieder aufgeladen werden muss.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Batterielebensdauer',
								text: `Bis zu ca. __5000 Anwendungen__`,
								icon: 'icn-batterylifetime',
							},
						],
					},
				],
			},
			{
				id: 5,
				releaseYear: '2011',
				devYears: '2009-11',
				name: 'THS 2.1',
				model: 'ths_21',
				image: 'ths_21',
				png_seq: 'ths_21',
				spritesheet: 'device_5',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'tablet',
					text:
						'Tablet computers become fasted adopted technology hardware in history.',
				},
				features: [
					{
						title: 'Über dieses Gerät',
						blocks: [
							{
								text: `Dies war der erste Prototyp eines industriellen Designs, der für die Verbraucherforschung entwickelt wurde.`,
							},
						],
					},
					{
						title: 'Nutzererlebnis',
						blocks: [
							{
								text:
									'Der erste Prototyp im Industriedesign für die Verbraucherforschung. Verbesserte Konsistenz des sensorischen Erlebnisses.',
								icon: 'icn-sensoryxp',
							},
							{
								text: `Von nun an Herstellung von Tabaksträngen: gewellter Tabak.`,
								icon: 'icn-crimpedtobacco',
							},
							{
								text:
									'Das Erlebnis ist auf 6 Minuten begrenzt (kontinuierliches erhitzen).',
								icon: 'icn-6min',
							},
						],
					},
					{
						title: 'Erhitzungstechnologie',
						blocks: [
							{
								subtitle: `Erhitzung von Innen`,
								text: `Der Tabak wird vom Kern her erhitzt. Die Heat Control Technology™ überwacht die Temperatur des Tabaks, um einen gleichbleibenden Geschmack und ein gleichbleibendes Erlebnis zu gewährleisten.`,
								icon: 'icn-internalheating',
							},
							{
								text: `Das Heizblatt misst __3 mm.__`,
								icon: 'icn-blade',
							},
							{
								text: `Die Spitzentemperatur des Tabaks während des Gebrauchs wird auf unter 400° C reduziert.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: 'Einführung eines neuen Akkus für das Ladegerät.',
								text: `Ausreichend für den Gebrauch eines Tabaksticks, bevor der Halter im Ladegerät wieder aufgeladen werden muss.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Batterielebensdauer',
								text: `Bis zu ca. __5000 Anwendungen__`,
								icon: 'icn-batterylifetime',
							},
						],
					},
				],
			},
			{
				id: 6,
				releaseYear: '2013',
				devYears: '2011-13',
				name: 'IQOS 2.2',
				model: 'iqos_22',
				image: 'iqos_22',
				png_seq: 'iqos_22',
				spritesheet: 'device_6',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'tablet',
					text:
						'Tablet computers become fasted adopted technology hardware in history.',
				},
				features: [
					{
						title: 'Über dieses Gerät',
						blocks: [
							{
								text: `Es wurde ein neues Industriedesign für die Vermarktung eingeführt.\n\nEs wurde ein neues Design des Heizblatts eingeführt, das ab dieser Version verwendet wird.`,
							},
						],
					},
					{
						title: 'Nutzererlebnis',
						blocks: [
							{
								text: `​1. Prototyp des Industriedesigns für die Verbraucherforschung. Verbesserte Konsistenz des sensorischen Erlebnis.`,
								icon: 'icn-sensoryxp',
							},
							{
								text: `Einführung des Reinigungssets.`,
								icon: 'icn-cleaningkit',
							},
							{
								text:
									'Gleichmässiges Ziehen und ein Nutzungsziel von __6 Minuten__ oder __14 Zügen__.',
								icon: 'icn-6min-14puff-de',
							},
						],
					},
					{
						title: 'Erhitzungstechnologie',
						blocks: [
							{
								subtitle: `Erhitzung von Innen`,
								text: `Der Tabak wird vom Kern her erhitzt. Die Heat Control Technology™ überwacht die Temperatur des Tabaks, um einen gleichbleibenden Geschmack und ein gleichbleibendes Erlebnis zu gewährleisten.`,
								icon: 'icn-internalheating',
							},
							{
								text: `Das Heizblatt misst __5 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `Die Spitzentemperatur des Tabaks während des Gebrauchs wird auf unter 400° C reduziert.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: 'Einführung eines neuen Akkus für das Ladegerät.',
								text: `Ausreichend für den Gebrauch eines Tabaksticks, bevor der Halter im Ladegerät wieder aufgeladen werden muss.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Batterielebensdauer',
								text: `Bis zu ca. __7300 Anwendungen__`,
								icon: 'icn-batterylifetime',
							},
						],
					},
				],
			},
			{
				id: 7,
				releaseYear: '2014',
				devYears: '2014-15',
				name: 'IQOS 2.4',
				model: 'iqos_24',
				image: 'iqos_24',
				png_seq: 'iqos_24',
				spritesheet: 'device_7',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'tablet',
					text:
						'Tablet computers become fasted adopted technology hardware in history.',
				},
				features: [
					{
						title: 'Über dieses Gerät',
						blocks: [
							{
								text: `Die Ergonomie des Geräts wurde verbessert und das Design wurde für eine gross angelegte Produktion entwickelt.`,
							},
						],
					},
					{
						title: 'Nutzererlebnis',
						blocks: [
							{
								text:
									'Die Ergonomie des Geräts wurde verbessert und das Produkt wurde für eine gross angelegte Produktion entwickelt.',
								icon: 'icn-size',
							},
							{
								text: `Einführung des Reinigungssets.`,
								icon: 'icn-cleaningkit',
							},
							{
								text:
									'Gleichmässiges Ziehen und ein Nutzungsziel von __6 Minuten__ oder __14 Zügen__.',
								icon: 'icn-6min-14puff-de',
							},
						],
					},
					{
						title: 'Erhitzungstechnologie',
						blocks: [
							{
								subtitle: `Erhitzung von Innen`,
								text: `Der Tabak wird vom Kern her erhitzt. Die Heat Control Technology™ überwacht die Temperatur des Tabaks, um einen gleichbleibenden Geschmack und ein gleichbleibendes Erlebnis zu gewährleisten.`,
								icon: 'icn-internalheating',
							},
							{
								text: `Das Heizblatt misst __5 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `Die Spitzentemperatur des Tabaks während des Gebrauchs wird auf unter 400° C reduziert.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: 'Einführung eines neuen Akkus für das Ladegerät.',
								text: `Ausreichend für den Gebrauch eines Tabaksticks, bevor der Halter im Ladegerät wieder aufgeladen werden muss.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Batterielebensdauer',
								text: `Bis zu ca. __7300 Anwendungen__`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Akku-Ladezeit',
								text: `Halter: 4:10min\nLadegerät: 90min`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Akku-Kapazität',
								text: `2900 mAh nominal`,
								icon: 'icn-batterycapacity',
							},
						],
					},
				],
			},
			{
				id: 11,
				releaseYear: '2019',
				devYears: '2019',
				name: 'IQOS 3 DUO',
				model: 'iqos_3_duo',
				image: 'iqos_3_duo',
				png_seq: 'iqos_3_duo',
				spritesheet: 'device_8',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'cloud',
					text:
						'The emergence of Amazon Web Services (AWS) ushers in cloud computing revolution.',
				},
				features: [
					{
						title: 'Über dieses Gerät',
						blocks: [
							{
								text: `Die Ergonomie und das Design wurden mit der Einführung einer knopflosen Seitenöffnung geändert.`,
							},
						],
					},
					{
						title: 'Nutzererlebnis',
						blocks: [
							{
								text: 'Kompaktes und ergonomisches Design.',
								icon: 'icn-size',
							},
							{
								text: `Nahfeldkommunikation für den Zugriff auf das Internet zur einfachen Registrierung.`,
								icon: 'icn-nearfield',
							},
							{
								text: 'Verbindungsmöglichkeit: Bluetooth, passiv.',
								icon: 'icn-bluetooth',
							},
							{
								text: 'Doppelte LED-Anzeige.',
								icon: 'icn-doubleled',
							},
							{
								subtitle: 'Halter-Ladegerät',
								text: 'Erlebnisse ohne Nachladen.',
								icon: 'icn-2-20-times-de',
							},
						],
					},
					{
						title: 'Erhitzungstechnologie',
						blocks: [
							{
								subtitle: `Erhitzung von Innen`,
								text: `Der Tabak wird vom Kern her erhitzt. Die Heat Control Technology™ überwacht die Temperatur des Tabaks, um einen gleichbleibenden Geschmack und ein gleichbleibendes Erlebnis zu gewährleisten.`,
								icon: 'icn-internalheating',
							},
							{
								text: `Das Heizblatt misst __5 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `Die Spitzentemperatur des Tabaks während des Gebrauchs wird auf unter 400° C reduziert.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: 'Einführung eines neuen Akkus für das Ladegerät.',
								text: `Der Akku hat einen höheren Ladestrom und eine grössere Kapazität, um 2 aufeinanderfolgende Erlebnisse zu ermöglichen.`,
								icon: 'icn-times2',
							},
							{
								subtitle: 'Batterielebensdauer',
								text: `Bis zu ca. __14600 Anwendungen__`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Akku-Ladezeit',
								text: `Halter:\n Av. 1:50min, 0-1 use\nAv. 3:25min, 1-2 use\nAv. 5:15min, 0-2 use\n\nLadegerät:\n20min.`,
								icon: 'icn-batterychargingtime',
							},
							{
								subtitle: 'Akku-Kapazität',
								text: `2900 mAh nominal`,
								icon: 'icn-batterycapacity',
							},
						],
					},
				],
			},
			{
				id: 12,
				releaseYear: '2021',
				devYears: '2020',
				name: 'ILUMA PRIME',
				model: 'iluma_prime',
				image: 'iluma_prime',
				png_seq: 'iluma_prime',
				spritesheet: 'device_9',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'cloud',
					text:
						'The emergence of Amazon Web Services (AWS) ushers in cloud computing revolution.',
				},
				features: [
					{
						title: 'Über dieses Gerät',
						blocks: [
							{
								text: `Nach dem Gebrauch ist keine Reinigung erforderlich, da es keine Tabakrückstände gibt.\n\nDie Induktionstechnologie wird auch in IQOS ILUMA ONE verwendet, so dass mit einer vollen Ladung bis zu 20 Erfahrungen gemacht werden können.`,
							},
						],
					},
					{
						title: 'Erfahrung des Nutzers',
						blocks: [
							{
								text: 'Neues Design mit einer umhüllenden Klappe.',
								icon: 'icn-size',
							},
							{
								subtitle: `Automatischer Start`,
								text: `Der Tabakstick wird erkannt, wenn er eingeführt wird, und das Gerät schaltet sich automatisch ein.`,
								// icon: 'icn-nearfield',
							},
							{
								text:
									'Der Beschleunigungsmesser zeigt an, ob das Gerät benutzt wird oder nicht, und schaltet es gegebenenfalls aus. Das "haptische Feedback" zeigt die verbleibende Dauer des Experiments an.',
								// icon: 'icn-bluetooth',
							},
						],
					},
					{
						title: 'Technologie für die Erwärmung',
						blocks: [
							{
								subtitle: `Smart Core Induktionssystem`,
								text: `Tabakstick, hergestellt aus zerkleinerten Tabakblättern, mit einem eingearbeiteten Metallelement in der Mitte des Sticks.`,
								// icon: 'icn-internalheating',
							},
							{
								text: `Vorrichtung ohne Klinge. Innovative metallische Heizelemente von ca. 4x12mm, die im Inneren des Tabaksticks platziert sind.`,
								// icon: 'icn-blade',
							},
						],
					},
					{
						title: 'Akku',
						blocks: [
							{
								subtitle: 'Lebensdauer der Batterie',
								text: `bis zu __14600 Sitzungen__.`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Ladezeit',
								text: `Av. 1:50 min, 0-1 Verwendung. \n Av. 3:25 min, 1-2 Nutzungen. \n Av. 5:15 min, 0-2 Nutzungen.\n Av. 10:05 min, vollständiges Aufladen beim ersten Mal.`,
								icon: 'icn-batterychargingtime',
							},
							{
								subtitle: 'Akkukapazität',
								text: `2900 mAh nominal`,
								icon: 'icn-batterycapacity',
							},
						],
					},
				],
			},
		],
	},
]

export default data
