import React from 'react'
import { useBoolean, useMount } from 'react-use'

import { ReactComponent as Logo } from 'assets/brand-logo.svg'

import { Root } from './BrandLogo.styled.js'

const BrandLogo = ({ altLayout }) => {
  const [mounted, setMounted] = useBoolean(false)

  useMount(() => {
    setMounted(true)
  })

  return (
    <Root
      altLayout={altLayout}
      show={mounted}
      variant="purple_500"
    >
      <Logo></Logo>
    </Root>
  )
}

export default BrandLogo
