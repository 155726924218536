import styled from 'styled-components/macro'

export const Root = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  .Inner {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const FrameContainer = styled.div`
  width: ${({ w }) => w}px;
  height: ${({ h }) => h}px;
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }
`
