import React, { useState } from 'react'
import { useTransition, useSpring, animated } from '@react-spring/web'
import { ScaleLoader } from 'react-spinners'

import { useApp } from 'store/AppStore'

import ProductSelector from './ProductSelector'
import ProductSlider from './ProductSlider'
import FeatureBox from './FeatureBox'
import Modal from './Modal'

import { Footer, Header, Root } from './Timeline.styled.js'
import BrandLogo from 'components/BrandLogo'

const Timeline = () => {
	const { isReady, altLayout, isShort, isPortrait } = useApp()
	const [modalVariant, setModalVariant] = useState()

	const modalTransition = useTransition(modalVariant, {
		from: {
			y: altLayout ? '0%' : '100%',
			x: altLayout ? '100%' : '0%',
			opacity: 0,
		},
		enter: { y: altLayout ? '0%' : '0%', x: altLayout ? '0%' : '0%', opacity: 1 },
		leave: {
			y: altLayout ? '0%' : '50%',
			x: altLayout ? '50%' : '0%',
			opacity: 0,
		},
	})

	const { headerY, footerY, sliderX } = useSpring({
		from: { headerY: '-100%', footerY: '100%', sliderX: '100%', dykScale: 0 },
		to: [
			{ headerY: isReady ? '0%' : '-100%' },
			{ footerY: isReady ? '0%' : '300%' },
			{ sliderX: isReady ? '0%' : altLayout ? '200%' : '100%' },
		],
		delay: (key) => {
			if (key === 'dykScale') {
				return 5000
			}
			return 0
		},
	})

	const loaderTransition = useTransition(!isReady, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		delay: isReady ? 0 : 1000,
	})

	const showLogo = (!isPortrait && !isShort) || altLayout

	return (
		<Root blur={modalVariant !== undefined} altLayout={altLayout}>
			{loaderTransition(
				(styles, item) =>
					item && (
						<animated.div className='Loader' style={styles}>
							<ScaleLoader color='currentColor'></ScaleLoader>
						</animated.div>
					)
			)}
			<div className='Inner'>
				<Header style={{ y: headerY }} $altLayout={altLayout}>
					{showLogo && <BrandLogo altLayout={altLayout}></BrandLogo>}
					<FeatureBox></FeatureBox>
				</Header>
				<div className='Body'>
					<ProductSlider style={{ x: sliderX }}></ProductSlider>
				</div>
				<Footer style={{ y: footerY }} $altLayout={altLayout}>
					<ProductSelector
						handleModalVariant={(variant) => setModalVariant(variant)}
					></ProductSelector>
				</Footer>
			</div>
			{modalTransition(
				(styles, item) =>
					item !== undefined && (
						<Modal
							variant={item}
							closeModal={() => setModalVariant()}
							style={styles}
						></Modal>
					)
			)}
		</Root>
	)
}

export default Timeline
