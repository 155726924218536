import React, { memo, useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'rc-scrollbars'
import { useKey } from 'react-use'
import useSpring from 'react-use/lib/useSpring'
import { useTransition, animated } from '@react-spring/web'
import { clamp } from 'lodash'

import productImages from 'assets/product-images'

import { useApp } from 'store/AppStore'

import {
	Root,
	Products,
	ProductBox,
	GroupButton,
} from './ProductSelector.styled.js'

const Product = ({
	selected,
	name,
	image,
	devYears,
	handleSelected,
	productIndex,
}) => {
	const { setSelectedProduct, isShort } = useApp()

	const boxRef = useRef()

	useEffect(() => {
		if (selected) {
			handleSelected(boxRef.current.offsetLeft)
		} else {
			boxRef.current.blur()
		}
	}, [selected, handleSelected])

	return (
		<ProductBox
			ref={boxRef}
			onClick={() => setSelectedProduct(productIndex)}
			selected={selected}
		>
			<div className='Inner'>
				{!isShort && <div className='ProductModel'>{productImages[image]}</div>}
				<div className='Year'>{devYears}</div>
				<div className='Name'>{name}</div>
			</div>
		</ProductBox>
	)
}

const productsMap = (products, selectedIndex, handleSelected) => {
	return products.map((product, id) => (
		<Product
			key={product.id}
			{...product}
			selected={selectedIndex === id}
			productIndex={id}
			handleSelected={handleSelected}
		></Product>
	))
}

const ProductSelector = ({ handleModalVariant }) => {
	const [keyboardNav, setKeyboardNav] = useState(0)
	const [scrollPos, setScrollPos] = useState(0)
	const springPos = useSpring(scrollPos, 0.5, 5)

	const {
		products,
		selectedProduct,
		setSelectedProduct,
		selectedProductIndex,
		isShort,
		isPortrait,
		altLayout,
	} = useApp()

	const scrollRef = useRef()

	const scrollTo = (pos) => {
		setScrollPos(pos - 20)
	}

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollLeft(springPos)
		}
	}, [springPos])

	useEffect(() => {
		if (keyboardNav !== 0) {
			const nextSlideIndex = clamp(
				selectedProductIndex + keyboardNav,
				0,
				products.length - 1
			)
			setKeyboardNav(0)
			setSelectedProduct(nextSlideIndex)
		}
	}, [
		keyboardNav,
		setKeyboardNav,
		selectedProductIndex,
		products.length,
		setSelectedProduct,
	])

	const goBack = () => setKeyboardNav(-1)
	const goForward = () => setKeyboardNav(1)

	useKey('ArrowLeft', goBack)
	useKey('ArrowRight', goForward)

	const btnTransition = useTransition(selectedProduct.groupName, {
		from: { len: 0, life: 0 },
		enter: (item) => [{ life: 1 }, { len: item.length }],
		leave: { len: 0, life: 0 },
	})

	return (
		<Root>
			<Products isShort={isShort} altLayout={altLayout}>
				{isPortrait ? (
					<Scrollbars ref={scrollRef}>
						<div className='Inner'>
							{productsMap(products, selectedProductIndex, scrollTo)}

							{altLayout && <ProductBox dummy></ProductBox>}
						</div>
					</Scrollbars>
				) : (
					<div className='Inner'>
						{productsMap(products, selectedProductIndex, scrollTo)}
					</div>
				)}
			</Products>
			<GroupButton
				altLayout={altLayout}
				onClick={() => handleModalVariant(selectedProduct.groupId)}
			>
				{btnTransition(({ len }, item) => (
					<animated.span>
						{len.to((l) => `‍${item.substring(0, parseInt(l))}`)}
					</animated.span>
				))}
			</GroupButton>
		</Root>
	)
}

export default memo(ProductSelector)
