import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

export const Root = styled.div`
  height: ${({ openHeight, collapsed }) => (collapsed ? 0 : openHeight)}px;
  transition: height ${({ openHeight, speed }) => openHeight / (speed * 1000)}s;
  overflow: hidden;
`

const Collapsible = ({ children, collapsed, speed = 1, ...rest }) => {
  const [openHeight, setOpenHeight] = useState(0)
  const elRef = useRef()

  useEffect(() => {
    setOpenHeight(elRef.current.scrollHeight)
  }, [children, setOpenHeight])

  return (
    <Root {...rest} ref={elRef} openHeight={openHeight} speed={speed} collapsed={collapsed}>
      {children}
    </Root>
  )
}

export default Collapsible
