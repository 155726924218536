import {
	createContext,
	useContext,
	useEffect,
	useState,
	useCallback,
} from 'react'
import { useBoolean, useCounter, useMedia, useSearchParam } from 'react-use'

import languages from './languages'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const AppContext = createContext()

const { Provider } = AppContext

const metaTags = {
	en: {
		title: 'PMI Science',
		description: 'Over 30 years of inovation',
	},
	de: {
		title: 'PMI-Wissenschaft',
		description: 'Über 30 Jahre Innovation',
	},
	fr: {
		title: 'Sciences PMI',
		description: `Plus de 30 ans d'innovation`,
	},
}

const groupProducts = (data) => {
	return data.reduce((p, group, id) => {
		const { name: groupName, abbr, products } = group
		return [
			...p,
			...products.map((product) => ({ groupName, abbr, groupId: id, ...product })),
		]
	}, [])
}

const AppProvider = ({ children }) => {
	const [selectedProductIndex, setSelectedProductIndex] = useState(0)
	const [selectedFeature, setSelectedFeature] = useState()
	const [showFeatures, toggleFeatures] = useBoolean(false)
	const [loadCount, { inc }] = useCounter(0)
	const lang = useSearchParam('lang')

	// console.log(lang)
	const data = languages[lang || 'en']
	const products = groupProducts(data)
	const isShort = useMedia(`(max-height: 950px)`)
	const isPortrait = useMedia(`(max-width: 1023px)`)
	const altLayout = useMedia(`(max-height: 760px) and (orientation: landscape)`)

	useEffect(() => {
		setSelectedFeature()
	}, [selectedProductIndex, setSelectedFeature])

	const loadInc = useCallback(
		(val) => {
			inc(val)
		},
		[inc]
	)

	const setSelectedProduct = useCallback(
		(index) => {
			setSelectedProductIndex(index)
		},
		[setSelectedProductIndex]
	)

	const selectedProduct = products[selectedProductIndex]
	const isReady = loadCount === products.length

	// console.log(altLayout)
	return (
		<HelmetProvider>
			<Provider
				value={{
					data,
					lang: lang || 'en',
					products,
					selectedProductIndex,
					setSelectedProduct,
					selectedFeature,
					setSelectedFeature,
					selectedProduct,
					showFeatures,
					toggleFeatures,
					loadInc,
					isReady,
					isShort,
					isPortrait,
					altLayout,
				}}
			>
				<Helmet>
					<html lang={lang || 'en'} />
					<title>{metaTags[lang || 'en'].title}</title>
					<meta name='description' content={metaTags[lang || 'en'].description} />
				</Helmet>
				{children}
			</Provider>
		</HelmetProvider>
	)
}

export const useApp = () => useContext(AppContext)

export default AppProvider
