import styled from 'styled-components/macro'

import { colors } from 'styled/theme.styled'

export const Root = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: blur(${({ selected }) => (selected ? 0 : 4)}px);
  transition: all 0.2s;
`

export const ImageLayer = styled.div`
  width: 100%;
  height: 100%;
  padding: 120px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  > svg {
    width: 100%;
    height: 100%;

    * {
      stroke-width: 2px;
      stroke: ${colors.darkBlue};
    }
  }
`
