const data = [
	{
		name: 'Système à chauffage électrique pour cigarettes',
		abbr: 'escss',
		description: `L'EHCSS se compose d'une cigarette spécialement conçue contenant du tabac roulé dans un "tapis" de tabac, enveloppé dans du papier à cigarette. Cette cigarette spéciale était insérée dans un appareil de chauffage alimenté par une batterie et équipé d'un réseau de huit lames qui chauffaient le tabac et minimisaient les brûlures pendant la bouffée.`,
		products: [
			{
				id: 1,
				releaseYear: '1998',
				devYears: '1990-98',
				name: 'Accord',
				model: 'accord_1',
				image: 'accord_1',
				png_seq: 'accord_1',
				spritesheet: 'device_1',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `90's`,
					icon: 'www',
					text: 'Tim Berners-Lee invents hypertext.',
				},
				features: [
					{
						title: 'À propos de cet appareil',
						blocks: [
							{
								text: `Une cigarette spécialement conçue était insérée dans un dispositif de chauffage alimenté par batterie et équipé d'un ensemble de huit lames qui chauffaient le tabac et minimisaient la combustion pendant la bouffée.`,
							},
						],
					},
					{
						title: "Expérience de l'utilisateur",
						blocks: [
							{
								text:
									'Un capteur de débit déclenche le cycle de chauffe pour chacune des lames lorsque la bouffée est détectée.',
								icon: 'icn-flowsensor',
							},
							{
								text: 'Expérience limitée à 8 bouffées par cigarette.',
								icon: 'icn-times8',
							},
						],
					},
				],
			},
			{
				id: 2,
				releaseYear: '2002',
				devYears: 'Milieu des années 1990',
				name: 'Accord',
				model: 'accord_2',
				image: 'accord_2',
				png_seq: 'accord_2',
				spritesheet: 'device_2',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `90's`,
					icon: 'www',
					text: 'Tim Berners-Lee invents hypertext.',
				},
				features: [
					{
						title: 'À propos de cet appareil',
						blocks: [
							{
								text: `Une cigarette spécialement conçue était insérée dans un dispositif de chauffage alimenté par batterie et équipé d'un ensemble de huit lames qui chauffaient le tabac et minimisaient la combustion pendant la bouffée.`,
							},
						],
					},
					{
						title: "Expérience de l'utilisateur",
						blocks: [
							{
								text:
									'Un capteur de débit déclenche le cycle de chauffe pour chacune des lames lorsque la bouffée est détectée.',
								icon: 'icn-flowsensor',
							},
							{
								text: 'Expérience limitée à 8 bouffées par cigarette.',
								icon: 'icn-times8',
							},
						],
					},
					{
						title: 'Technologie de chauffage',
						blocks: [
							{
								subtitle: `Chauffage externe`,
								text: `Une cigarette spécialement conçue contenant du tabac conventionnel roulé dans un ""tapis"" de tabac était enveloppée de papier à cigarette. Cette cigarette spéciale était insérée dans un dispositif de chauffage alimenté par une batterie et équipé d'un réseau de huit lames.`,
								icon: 'icn-externalheating',
							},
							{
								text: `Le dispositif de chauffage était équipé d'un ensemble de huit lames fabriquées à partir d'un alliage de fer et d'aluminium, une lame pour chacune des huit bouffées possibles par cigarette.`,
								icon: 'icn-times8',
							},

							{
								text: `Avec cette conception, le tabac atteint une température maximale d'environ 500 ºC.`,
								icon: 'icn-500c',
							},
						],
					},
				],
			},
			{
				id: 3,
				releaseYear: '2006',
				devYears: '2000-04',
				name: 'Heatbar',
				model: 'heatbar',
				image: 'heatbar',
				png_seq: 'heatbar',
				spritesheet: 'device_3',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `00's`,
					icon: 'msg',
					text: 'Web 2.0 technology morphs into what will be known as social media.',
				},
				features: [
					{
						title: 'À propos de cet appareil',
						blocks: [
							{
								text: `L'EHCSS série K était plus sensible dans la détection et l'activation des bouffées, et comprenait une batterie rechargeable de qualité supérieure.\n\nDes modifications ont été apportées à la cigarette, notamment l'introduction d'un filtre à charbon hautement activé.`,
							},
						],
					},
					{
						title: "Expérience de l'utilisateur",
						blocks: [
							{
								text:
									'Un capteur de débit déclenche le cycle de chauffe pour chacune des lames lorsque la bouffée est détectée.',
								icon: 'icn-flowsensor',
							},
							{
								text:
									"L'arôme, la filtration, le débit d'air et le contrôle de la chaleur ont été améliorés pour réduire davantage la formation de produits de pyrolyse et de combustion indésirables.",
								icon: 'icn-airflow',
							},
							{
								text:
									"Augmentation de la quantité d'aérosol délivrée grâce à une lame plus grande.",
								icon: 'icn-sizeincrease',
							},
							{
								text: 'Expérience limitée à 8 bouffées par cigarette.',
								icon: 'icn-times8',
							},
						],
					},
					{
						title: 'Technologie de chauffe',
						blocks: [
							{
								subtitle: `Chauffage externe`,
								text:
									'Une cigarette de conception spécifique contenant du tabac conventionnel roulé dans un ""tapis"" de tabac a été enveloppée de papier à cigarette. Cette cigarette spéciale a été insérée dans un appareil de chauffage alimenté par une batterie et équipé d\'un réseau de huit lames.',
								icon: 'icn-externalheating',
							},
							{
								text:
									"Le dispositif de chauffage était équipé d'un ensemble de huit lames fabriquées à partir d'un alliage de fer et d'aluminium, une lame pour chacune des huit bouffées possibles par cigarette.",
								icon: 'icn-times8',
							},
							{
								text:
									"Avec cette conception, le tabac atteint une température maximale d'environ 500 ºC.",
								icon: 'icn-500c',
							},
						],
					},
				],
			},
		],
	},
	{
		name: 'Sytème de chauffage du tabac',
		abbr: 'ths',
		description: `Le système de chauffage du tabac (THS en anglais) est un produit 
        spécialement conçu pour chauffer un stick de tabac, sans le brûler. Ceci est réalisé grâce à un chauffage du bâtonnet de tabac de l'intérieur vers l'extérieur du stick.`,
		products: [
			{
				id: 4,
				releaseYear: '2009',
				devYears: '2007-09',
				name: 'THS 2.0',
				model: 'ths_20',
				image: 'ths_20',
				png_seq: 'ths_20',
				spritesheet: 'device_4',
				frame: {
					width: 350,
					height: 560,
				},
				dyk: {
					year: `00's`,
					icon: 'msg',
					text: 'Web 2.0 technology morphs into what will be known as social media.',
				},
				features: [
					{
						title: 'À propos de cet appareil',
						blocks: [
							{
								text: `Il s'agit du premier prototype de laboratoire en deux parties doté d'un système interne de chauffage des sticks de tabac et d'un contrôle de la température de la lame chauffante.`,
							},
						],
					},
					{
						title: "Expérience de l'utilisateur",
						blocks: [
							{
								text:
									"Introduction d'un système en deux parties, un support et un chargeur. Réduction significative de la taille du support.",
								icon: 'icn-size',
							},
							{
								subtitle: 'Révision du stick de tabac:',
								text:
									'Traitement des feuilles de tabac broyées. Nouvel élément filtrant.',
								icon: 'icn-tobaccostick',
							},
							{
								text: 'Expérience limitée à 6 minutes (chauffage continu).',
								icon: 'icn-6min',
							},
						],
					},
					{
						title: 'Technologie de chauffage',
						blocks: [
							{
								subtitle: `Chauffage interne`,
								text: `Le tabac est chauffé à partir du centre. La technologie Heat Control™ contrôle la température du tabac pour garantir un goût et une expérience homogènes.`,
								icon: 'icn-internalheating',
							},
							{
								text: `La lame chauffante mesure __3 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `La température maximale du tabac pendant l'utilisation est réduite à moins de 400°C.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: `Introduction d'une nouvelle batterie pour le chargeur.`,
								text: `Suffisante pour l'utilisation d'un bâtonnet de tabac avant de devoir être rechargée dans le chargeur.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Durée de vie de la batterie',
								text: `Jusqu'à environ __5000 sessions__`,
								icon: 'icn-batterylifetime',
							},
						],
					},
				],
			},
			{
				id: 5,
				releaseYear: '2011',
				devYears: '2009-11',
				name: 'THS 2.1',
				model: 'ths_21',
				image: 'ths_21',
				png_seq: 'ths_21',
				spritesheet: 'device_5',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'tablet',
					text:
						'Tablet computers become fasted adopted technology hardware in history.',
				},
				features: [
					{
						title: 'À propos de cet appareil',
						blocks: [
							{
								text: `Il s'agit du premier prototype industriel développé pour la recherche avec les consommateurs.`,
							},
						],
					},
					{
						title: "Expérience de l'utilisateur",
						blocks: [
							{
								text: `Premier prototype de conception industrielle destiné à la recherche avec les consommateurs. Amélioration de la cohérence de l'expérience sensorielle.`,
								icon: 'icn-sensoryxp',
							},
							{
								text: `À partir de maintenant, fabrication de boudins de tabac: tabac "froissé".`,
								icon: 'icn-crimpedtobacco',
							},
							{
								text: `Expérience limitée à 6 minutes (chauffage continu).`,
								icon: 'icn-6min',
							},
						],
					},
					{
						title: 'Technologie de chauffage',
						blocks: [
							{
								subtitle: `Chauffage interne`,
								text: `Le tabac est chauffé à partir du centre. La technologie Heat Control™ contrôle la température du tabac pour garantir un goût et une expérience homogènes.`,
								icon: 'icn-internalheating',
							},
							{
								text: `La lame chauffante mesure __3 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `La température maximale du tabac pendant l'utilisation est réduite à moins de 400°C.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: `Introduction d'une nouvelle batterie pour le chargeur.`,
								text: `Suffisante pour l'utilisation d'un bâtonnet de tabac avant de devoir être rechargée dans le chargeur.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Durée de vie de la batterie',
								text: `Jusqu'à environ __5000 sessions__`,
								icon: 'icn-batterylifetime',
							},
						],
					},
				],
			},
			{
				id: 6,
				releaseYear: '2013',
				devYears: '2011-13',
				name: 'IQOS 2.2',
				model: 'iqos_22',
				image: 'iqos_22',
				png_seq: 'iqos_22',
				spritesheet: 'device_6',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'tablet',
					text:
						'Tablet computers become fasted adopted technology hardware in history.',
				},
				features: [
					{
						title: 'À propos de cet appareil',
						blocks: [
							{
								text: `Un nouveau design industriel a été introduit pour la commercialisation.\n\nUn nouveau design de lame chauffante a été introduit, utilisé à partir de cette version.`,
							},
						],
					},
					{
						title: "Expérience de l'utilisateur",
						blocks: [
							{
								text: `Amélioration de la cohérence de l'expérience sensorielle.`,
								icon: 'icn-sensoryxp',
							},
							{
								text: `Introduction du kit de nettoyage.`,
								icon: 'icn-cleaningkit',
							},
							{
								text: `Une bouffée constante est assurée et l'objectif d'utilisation est de __6 minutes__ ou __14 bouffées__.`,
								icon: 'icn-6min-14puff-fr',
							},
						],
					},
					{
						title: 'Technologie de chauffage',
						blocks: [
							{
								subtitle: `Chauffage interne`,
								text: `Le tabac est chauffé à partir du centre. La technologie Heat Control™ contrôle la température du tabac pour garantir un goût et une expérience homogènes.`,
								icon: 'icn-internalheating',
							},
							{
								text: `La lame chauffante mesure __5 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `La température maximale du tabac pendant l'utilisation est réduite à moins de 400°C.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: `Introduction d'une nouvelle batterie pour le chargeur.`,
								text: `Suffisante pour l'utilisation d'un bâtonnet de tabac avant de devoir être rechargée dans le chargeur.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Durée de vie de la batterie',
								text: `Jusqu'à environ __7300 sessions__`,
								icon: 'icn-batterylifetime',
							},
						],
					},
				],
			},
			{
				id: 7,
				releaseYear: '2014',
				devYears: '2014-15',
				name: 'IQOS 2.4',
				model: 'iqos_24',
				image: 'iqos_24',
				png_seq: 'iqos_24',
				spritesheet: 'device_7',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'tablet',
					text:
						'Tablet computers become fasted adopted technology hardware in history.',
				},
				features: [
					{
						title: 'À propos de cet appareil',
						blocks: [
							{
								text: `L'ergonomie du dispositif a été améliorée et la conception a été développée pour une fabrication à grande échelle.`,
							},
						],
					},
					{
						title: "Expérience de l'utilisateur",
						blocks: [
							{
								text: `Ergonomie améliorée et produit conçu pour une fabrication à grande échelle.`,
								icon: 'icn-size',
							},
							{
								text: `Introduction du kit de nettoyage.`,
								icon: 'icn-cleaningkit',
							},
							{
								text: `Une bouffée constante est assurée et l'objectif d'utilisation est de __6 minutes__ ou __14 bouffées__.`,
								icon: 'icn-6min-14puff-fr',
							},
						],
					},
					{
						title: 'Technologie de chauffage',
						blocks: [
							{
								subtitle: `Chauffage interne`,
								text: `Le tabac est chauffé à partir du centre. La technologie Heat Control™ contrôle la température du tabac pour garantir un goût et une expérience homogènes.`,
								icon: 'icn-internalheating',
							},
							{
								text: `La lame chauffante mesure __5 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `La température maximale du tabac pendant l'utilisation est réduite à moins de 400°C.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: `Introduction d'une nouvelle batterie pour le chargeur.`,
								text: `Suffisante pour l'utilisation d'un bâtonnet de tabac avant de devoir être rechargée dans le chargeur.`,
								icon: 'icn-times1',
							},
							{
								subtitle: 'Durée de vie de la batterie',
								text: `Jusqu'à environ __7300 sessions__`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Temps de charge de la batterie',
								text: `Support : 4:10min\nChargeur : 90 minutes`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Capacité de la batterie',
								text: `2900 mAh nominal`,
								icon: 'icn-batterycapacity',
							},
						],
					},
				],
			},
			{
				id: 11,
				releaseYear: '2019',
				devYears: '2019',
				name: 'IQOS 3 DUO',
				model: 'iqos_3_duo',
				image: 'iqos_3_duo',
				png_seq: 'iqos_3_duo',
				spritesheet: 'device_8',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'cloud',
					text:
						'The emergence of Amazon Web Services (AWS) ushers in cloud computing revolution.',
				},
				features: [
					{
						title: 'À propos de cet appareil',
						blocks: [
							{
								text: `L'ergonomie et le design ont été modifiés avec l'introduction d'une ouverture latérale sans bouton.`,
							},
						],
					},
					{
						title: "Expérience de l'utilisateur",
						blocks: [
							{
								text: `Design compact et ergonomique.`,
								icon: 'icn-size',
							},
							{
								text: `Communication en champ proche pour accéder au web et faciliter l'enregistrement.`,
								icon: 'icn-nearfield',
							},
							{
								text: `Connectivité: Bluetooth, passif.`,
								icon: 'icn-bluetooth',
							},
							{
								text: `Double indicateur LED.`,
								icon: 'icn-doubleled',
							},
							{
								subtitle: 'Porte-chargeur',
								text: `Expériences sans recharge.`,
								icon: 'icn-2-20-times-fr',
							},
						],
					},
					{
						title: 'Technologie de chauffage',
						blocks: [
							{
								subtitle: `Chauffage interne`,
								text: `Le tabac est chauffé à partir du cœur. La technologie Heat Control™ contrôle la température du tabac pour garantir un goût et une expérience homogènes.`,
								icon: 'icn-internalheating',
							},
							{
								text: `La lame chauffante mesure __5 mm__.`,
								icon: 'icn-blade',
							},
							{
								text: `La température maximale du tabac pendant l'utilisation est réduite à moins de 400°C.`,
								icon: 'icn-less400c',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: `Introduction d'une nouvelle batterie pour le chargeur.`,
								text: `La batterie a un courant de charge plus élevé et une plus grande capacité pour permettre deux expériences consécutives.`,
								icon: 'icn-times2',
							},
							{
								subtitle: 'Durée de vie de la batterie',
								text: `Jusqu'à environ __14600 sessions__`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Temps de charge de la batterie',
								text: `Support :\n Av. 1:50min, 0-1 utilisation\n Av. 3:25min, 1-2 utilisations\n Av. 5:15min, 0-2 utilisation\n \nChargeur :\n 20min.`,
								icon: 'icn-batterychargingtime',
							},
							{
								subtitle: 'Capacité de la batterie',
								text: `2900 mAh nominal`,
								icon: 'icn-batterycapacity',
							},
						],
					},
				],
			},
			{
				id: 12,
				releaseYear: '2021',
				devYears: '2020',
				name: 'ILUMA PRIME',
				model: 'iluma_prime',
				image: 'iluma_prime',
				png_seq: 'iluma_prime',
				spritesheet: 'device_9',
				frame: {
					width: 280,
					height: 448,
				},
				dyk: {
					year: `10's`,
					icon: 'cloud',
					text:
						'The emergence of Amazon Web Services (AWS) ushers in cloud computing revolution.',
				},
				features: [
					{
						title: 'À propos de cet appareil',
						blocks: [
							{
								text: `Aucun nettoyage n'est nécessaire après utilisation car il n'y a pas de résidus de tabac.\n\nLa technologie de l'induction est également utilisée dans IQOS ILUMA ONE, ce qui permet de vivre jusqu'à 20 expériences avec une charge complète.`,
							},
						],
					},
					{
						title: "Expérience de l'utilisateur",
						blocks: [
							{
								text: `Nouveau design avec un rabat enveloppant.`,
								icon: 'icn-size',
							},
							{
								subtitle: `Démarrage automatique`,
								text: `Le stick de tabac est détecté lorsqu'il est inséré et l'appareil s'allume automatiquement.`,
								// icon: 'icn-nearfield',
							},
							{
								text: `L'accéléromètre indique si le dispositif est utilisé ou non et l'éteint si nécessaire. Le "retour haptique" indique la durée restante de l'expérience.`,
								// icon: 'icn-bluetooth',
							},
						],
					},
					{
						title: 'Technologie de chauffe',
						blocks: [
							{
								subtitle: `Système d'induction Smart Core`,
								text: `Stick de tabac fabriqué à partir de feuilles de tabac broyées, avec un élément métallique incorporé au centre du bâtonnet.`,
								// icon: 'icn-internalheating',
							},
							{
								text: `Dispositif sans lame. Éléments chauffants métalliques innovants d'environ 4 x 12 mm placés à l'intérieur du bâtonnet de tabac.`,
								// icon: 'icn-blade',
							},
						],
					},
					{
						title: 'Batterie',
						blocks: [
							{
								subtitle: 'Durée de vie de la batterie',
								text: `jusqu'à __14600 sessions__.`,
								icon: 'icn-batterylifetime',
							},
							{
								subtitle: 'Temps de charge',
								text: `Av. 1:50 min, 0-1 utilisation \n Av. 3:25 min, 1-2 utilisations \n Av. 5:15 min, 0-2 utilisation\n Av. 10:05 min, charge complète la première fois`,
								icon: 'icn-batterychargingtime',
							},
							{
								subtitle: 'Capacité de la batterie',
								text: `2900 mAh nominal`,
								icon: 'icn-batterycapacity',
							},
						],
					},
				],
			},
		],
	},
]

export default data
