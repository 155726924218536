import styled, { css } from 'styled-components/macro'

import { colors } from 'styled/theme.styled'

export const Root = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	user-select: none;

	transform: scale(${({ selected }) => (selected ? 1 : 0.75)});
	transition: all 0.2s;

	> .Year {
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 4px;
		color: ${colors.purple_600};
	}

	> .Name {
		${({ isPortrait, altLayout }) =>
			altLayout
				? css`
						font-size: 8vmin;
				  `
				: isPortrait
				? css`
						font-size: 14vw;
				  `
				: css`
						font-size: 96px;
				  `};
		font-weight: 900;
		text-align: center;
		white-space: nowrap;
		color: ${colors.purple_600};
		opacity: 0.25;

		/* @supports (-webkit-text-stroke: 1px ${colors.darkBlue}) {
      color: transparent;
      -webkit-text-stroke: 1px ${colors.darkBlue};
    } */

		> * {
			display: inline-block;
		}
	}
`

export const FeatureButton = styled.div`
	position: absolute;
	bottom: 48px;
	right: calc(50% - 72px);
	width: 48px;
	height: 48px;
	border-radius: 999px;
	background-color: ${colors.purple_600};
	display: flex;
	justify-content: center;
	align-items: center;
	transform: scale(${({ selected }) => (selected ? 1 : 0)});
	transition: transform 0.3s;

	${({ altLayout }) =>
		altLayout &&
		css`
			bottom: 5vh;
		`};

	> svg {
		display: block;
		fill: ${colors.white};
		width: 24px;
	}

	&:hover {
		cursor: pointer;
	}
`
