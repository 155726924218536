import styled, { css } from 'styled-components/macro'

import { colors } from 'styled/theme.styled'

export const Root = styled.div`
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.rc-scrollbars-thumb.rc-scrollbars-thumb-h {
		background-color: ${colors.faintBlue} !important;
	}
`

export const Products = styled.div`
	width: 100%;
	height: ${({ isShort, altLayout }) => (altLayout ? 60 : isShort ? 80 : 190)}px;
	padding-bottom: ${({ altLayout }) => (altLayout ? 0 : 8)}px;

	.Inner {
		width: auto;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		padding: 0 20px 12px;

		@media only screen and (max-width: 767px) {
			justify-content: flex-start;
		}
	}
`

export const DummySpace = styled.div`
	flex: 0 0 auto;
	width: 100px;
	outline: 1px solid tomato;
	display: inline-block;
`
export const ProductBox = styled.div`
	position: relative;
	flex: 0 0 auto;
	width: 90px;
	${({ dummy }) =>
		dummy &&
		css`
			width: calc(var(--sidebar-width) - 24px);
		`};
	height: 100%;
	border-bottom: 1px solid
		${({ selected }) => (selected ? colors.purple_600 : colors.purple_300)};

	> .Inner {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		/* justify-content: flex-end; */
		justify-content: space-between;
		padding: 0 8px 8px;
		transition: all 0.3s;

		> .ProductModel {
			position: relative;
			width: 100%;
			height: 80px;
			margin-bottom: 12px;
			pointer-events: none;

			> svg {
				position: absolute;
				width: 100%;
				height: 100%;
				transform: scale(${({ selected }) => (selected ? 1.2 : 1)});
				transform-origin: bottom;
				transition: transform 0.3s;

				* {
					stroke-width: 4px;
					stroke: ${({ selected }) =>
						selected ? colors.purple_600 : colors.midGrey};
					transition: stroke 0.3s;
				}
			}
		}
		> .Year {
			font-size: 14px;
			text-align: center;
			/* white-space: nowrap; */
			color: ${colors.purple_600};
			opacity: 0.25;
			overflow: hidden;
			max-height: ${({ selected }) => (selected ? 0 : 50)}px;
			transition: max-height 0.35s;
		}

		> .Name {
			font-size: 14px;
			text-align: center;
			font-weight: bold;
			line-height: 1.5;
			color: ${colors.purple_600};
			overflow: hidden;
			max-height: ${({ selected }) => (selected ? 50 : 0)}px;
			transition: max-height 0.35s;
		}
	}

	@media (hover: hover) {
		&:hover {
			cursor: pointer;
			> .Inner {
				> .Year {
					max-height: 0px;
				}

				> .Name {
					max-height: 50px;
				}

				> .ProductModel {
					svg {
						transform: scale(1.2);
					}
				}
			}
		}
	}
`

export const GroupButton = styled.div`
	padding: 16px;
	border-radius: 999px;
	/* background-color: ${colors.purple_300}; */
	border: 1px solid ${colors.purple_600_a50};
	color: ${colors.purple_600};
	font-size: 12px;
	font-style: italic;
	text-transform: uppercase;
	user-select: none;

	&:hover {
		cursor: pointer;
	}

	${({ altLayout }) =>
		altLayout &&
		css`
			position: absolute;
			width: calc(var(--sidebar-width) - 24px);
			min-width: 250px;
			right: 12px;
			top: 0;
			text-align: center;
			transform: translate(0, -100%);
		`};
`
