import styled from 'styled-components/macro'

import { colors } from 'styled/theme.styled'

export const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  overflow: hidden;

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`
