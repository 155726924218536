import React, { useEffect, memo } from 'react'
import { useSprings, useSpring, animated, config } from '@react-spring/web'

import { ReactComponent as PlusIcon } from 'assets/uiIcons/plus.svg'
import { ReactComponent as MinusIcon } from 'assets/uiIcons/minus.svg'

import { useApp } from 'store/AppStore'

import { Root, FeatureButton } from './ContentLayer.styled.js'

const SPRING_DUR = 400

const ContentLayer = memo(
  ({ releaseYear, name, selected, showFeatures, toggleFeatures, isPortrait, altLayout }) => {
    const nameLetters = name.replace(/ /g, '\u00a0').split('')
    const numLetters = nameLetters.length
    const del = SPRING_DUR / numLetters

    const [springs, api] = useSprings(numLetters, (index) => ({
      from: {
        transform: `translate(${-index * 25}px, 400px) skew(60deg)`,
        opacity: 0,
        config: config.stiff,
      },
    }))

    const [yearStyles, yearApi] = useSpring(() => ({
      opacity: 0,
      letterSpacing: 100,
      delay: SPRING_DUR,
    }))

    useEffect(() => {
      if (selected) {
        api.start((index) => ({
          opacity: 1,
          transform: `translate(0px, 0px) skew(0deg)`,
          delay: index * del,
          reset: true,
        }))
        yearApi.start({
          opacity: 1,
          letterSpacing: 4,
          delay: SPRING_DUR,
        })
      } else {
        api.start((index) => ({
          opacity: 0,
          transform: `translate(-200px, 0px) skew(-45deg)`,
          delay: (index * del) / 2,
        }))
        yearApi.start({ opacity: 0, letterSpacing: 100, delay: SPRING_DUR })
      }
    }, [selected, api, del, yearApi])

    return (
      <Root
        selected={selected}
        isPortrait={isPortrait}
        altLayout={altLayout}
      >
        <animated.div
          className="Year"
          style={yearStyles}
        >
          {releaseYear}
        </animated.div>
        <div className="Name">
          {springs.map((styles, id) => (
            <animated.span
              key={id}
              style={styles}
            >
              {nameLetters[id]}
            </animated.span>
          ))}
        </div>
        <FeatureButton
          selected={selected}
          altLayout={altLayout}
          onClick={() => toggleFeatures()}
        >
          {showFeatures ? <MinusIcon></MinusIcon> : <PlusIcon></PlusIcon>}
        </FeatureButton>
      </Root>
    )
  }
)

const WrappedContent = (props) => {
  const { showFeatures, toggleFeatures, isPortrait, altLayout } = useApp()
  return (
    <ContentLayer
      {...props}
      {...{ showFeatures, toggleFeatures, isPortrait, altLayout }}
    ></ContentLayer>
  )
}

export default WrappedContent
