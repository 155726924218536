// import accord_1 from './accord_1.png'
// import accord_2 from './accord_2.png'
// import heatbar from './heatbar.png'
// import iqos_22 from './iqos_22.png'
// import iqos_24 from './iqos_24.png'
// import iqos_3_duo from './iqos_3_duo.png'
// import ths_20 from './ths_20.png'
// import ths_21 from './ths_21.png'
// import iluma_prime from './iluma_prime.png'

import { ReactComponent as Accord1 } from './accord_1.svg'
import { ReactComponent as Accord2 } from './accord_2.svg'
import { ReactComponent as Heatbar } from './heatbar.svg'
import { ReactComponent as Iqos22 } from './iqos_22.svg'
import { ReactComponent as Iqos24 } from './iqos_24.svg'
import { ReactComponent as Iqos3Duo } from './iqos_3_duo.svg'
import { ReactComponent as Ths20 } from './ths_20.svg'
import { ReactComponent as Ths21 } from './ths_21.svg'
import { ReactComponent as IlumaPrime } from './iluma_prime.svg'

const images = {
  accord_1: <Accord1></Accord1>,
  accord_2: <Accord2></Accord2>,
  heatbar: <Heatbar></Heatbar>,
  iqos_22: <Iqos22></Iqos22>,
  iqos_24: <Iqos24></Iqos24>,
  iqos_3_duo: <Iqos3Duo></Iqos3Duo>,
  ths_20: <Ths20></Ths20>,
  ths_21: <Ths21></Ths21>,
  iluma_prime: <IlumaPrime></IlumaPrime>,
}

export default images
