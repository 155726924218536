import styled, { css } from 'styled-components/macro'
import { colors } from 'styled/theme.styled'

export const Root = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background-color: ${colors.purple_300_a50};
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	pointer-events: none;

	${({ altLayout }) =>
		altLayout &&
		css`
			justify-content: center;
			align-items: flex-end;
			padding: 20px;
		`};

	> .Content {
		width: 100%;
		max-width: 600px;
		background-color: ${colors.white};
		color: ${colors.purple_600};
		border-radius: 16px;
		margin-bottom: 24px;

		${({ altLayout }) =>
			altLayout &&
			css`
				width: 90%;
				margin-bottom: 0;
			`};

		.Inner {
			padding: 48px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;

			${({ altLayout }) =>
				altLayout &&
				css`
					padding: 20px;
					flex-direction: row-reverse;
					justify-content: space-between;
				`};

			> .ContentWrapper {
				padding: 20px;
			}
		}

		h3 {
			/* text-transform: capitalize; */
			text-align: center;
			margin-bottom: 24px;
			font-size: 24px;
			font-style: italic;
			font-weight: normal;
			text-align: left;
		}

		p {
			font-size: 16px;
			font-style: italic;
			line-height: 1.5;
			margin-bottom: 40px;
		}
	}
`

export const CloseButton = styled.div`
	position: relative;
	flex: 0 0 auto;
	width: 64px;
	height: 64px;
	border-radius: 999px;
	border: 1px solid ${colors.purple_500};
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${colors.purple_600};
	pointer-events: all;

	svg {
		width: 16px;
		fill: currentColor;
	}

	&:hover {
		cursor: pointer;
	}
`
