import { ReactComponent as Icn220Times } from './icn-2-20-times.svg'
import { ReactComponent as Icn220TimesDe } from './icn-2-20-times-de.svg'
import { ReactComponent as Icn220TimesFr } from './icn-2-20-times-fr.svg'
import { ReactComponent as Icn500c } from './icn-500c.svg'
import { ReactComponent as Icn6min } from './icn-6min.svg'
import { ReactComponent as Icn6min14puff } from './icn-6min-14puff.svg'
import { ReactComponent as Icn6min14puffDe } from './icn-6min-14puff-de.svg'
import { ReactComponent as Icn6min14puffFr } from './icn-6min-14puff-fr.svg'
import { ReactComponent as IcnAirflow } from './icn-airflow.svg'
import { ReactComponent as IcnBatterycapacity } from './icn-batterycapacity.svg'
import { ReactComponent as IcnBatterychargingtime } from './icn-batterychargingtime.svg'
import { ReactComponent as IcnBatterylifetime } from './icn-batterylifetime.svg'
import { ReactComponent as IcnBlade } from './icn-blade.svg'
import { ReactComponent as IcnBluetooth } from './icn-bluetooth.svg'
import { ReactComponent as IcnCleaningkit } from './icn-cleaningkit.svg'
import { ReactComponent as IcnCrimpedtobacco } from './icn-crimpedtobacco.svg'
import { ReactComponent as IcnDoubleled } from './icn-doubleled.svg'
import { ReactComponent as IcnExternalheating } from './icn-externalheating.svg'
import { ReactComponent as IcnFlowsensor } from './icn-flowsensor.svg'
import { ReactComponent as IcnInternalheating } from './icn-internalheating.svg'
import { ReactComponent as IcnLess400c } from './icn-less400c.svg'
import { ReactComponent as IcnNearfield } from './icn-nearfield.svg'
import { ReactComponent as IcnSize } from './icn-size.svg'
import { ReactComponent as IcnSizeincrease } from './icn-sizeincrease.svg'
import { ReactComponent as IcnSensoryxp } from './icn-sensoryxp.svg'
import { ReactComponent as IcnTimes1 } from './icn-times1.svg'
import { ReactComponent as IcnTimes2 } from './icn-times2.svg'
import { ReactComponent as IcnTimes8 } from './icn-times8.svg'
import { ReactComponent as IcnTobaccostick } from './icn-tobaccostick.svg'

import device_1 from './device_1.png'
import device_2 from './device_2.png'
import device_3 from './device_3.png'

export const icons = {
	'icn-2-20-times': <Icn220Times></Icn220Times>,
	'icn-2-20-times-de': <Icn220TimesDe></Icn220TimesDe>,
	'icn-2-20-times-fr': <Icn220TimesFr></Icn220TimesFr>,
	'icn-500c': <Icn500c></Icn500c>,
	'icn-6min': <Icn6min></Icn6min>,
	'icn-6min-14puff': <Icn6min14puff></Icn6min14puff>,
	'icn-6min-14puff-de': <Icn6min14puffDe></Icn6min14puffDe>,
	'icn-6min-14puff-fr': <Icn6min14puffFr></Icn6min14puffFr>,
	'icn-airflow': <IcnAirflow></IcnAirflow>,
	'icn-batterycapacity': <IcnBatterycapacity></IcnBatterycapacity>,
	'icn-batterychargingtime': <IcnBatterychargingtime></IcnBatterychargingtime>,
	'icn-batterylifetime': <IcnBatterylifetime></IcnBatterylifetime>,
	'icn-blade': <IcnBlade></IcnBlade>,
	'icn-bluetooth': <IcnBluetooth></IcnBluetooth>,
	'icn-cleaningkit': <IcnCleaningkit></IcnCleaningkit>,
	'icn-crimpedtobacco': <IcnCrimpedtobacco></IcnCrimpedtobacco>,
	'icn-doubleled': <IcnDoubleled></IcnDoubleled>,
	'icn-externalheating': <IcnExternalheating></IcnExternalheating>,
	'icn-flowsensor': <IcnFlowsensor></IcnFlowsensor>,
	'icn-internalheating': <IcnInternalheating></IcnInternalheating>,
	'icn-less400c': <IcnLess400c></IcnLess400c>,
	'icn-nearfield': <IcnNearfield></IcnNearfield>,
	'icn-size': <IcnSize></IcnSize>,
	'icn-sizeincrease': <IcnSizeincrease></IcnSizeincrease>,
	'icn-sensoryxp': <IcnSensoryxp></IcnSensoryxp>,
	'icn-times1': <IcnTimes1></IcnTimes1>,
	'icn-times2': <IcnTimes2></IcnTimes2>,
	'icn-times8': <IcnTimes8></IcnTimes8>,
	'icn-tobaccostick': <IcnTobaccostick></IcnTobaccostick>,
}

export const images = {
	device_1,
	device_2,
	device_3,
}
