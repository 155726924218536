import styled, { css } from 'styled-components/macro'
import { animated } from '@react-spring/web'

export const Slides = styled(animated.div)`
  height: 100%;
  max-height: 680px;
  padding: 20px 0;
  display: flex;
  touch-action: none;
  pointer-events: all;
  margin: auto;
`

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: none;

  ${({ isPortrait, altLayout }) => css`
    ${Slides} {
      ${altLayout &&
      `
      padding: 0;
      `}
      ${isPortrait || altLayout
        ? `
          width: 100%;
          min-width: 100%;
          `
        : `
          width: 33.333%;
          min-width: 560px;
          `}
    }
  `}
`
