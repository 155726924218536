import { memo } from 'react'
import { Root } from './Slide.styled.js'
import ContentLayer from './ContentLayer'
import PngSequencer from 'components/PngSequencer/PngSequencer.jsx'

const Slide = ({ selected, visible, ...props }) => {
  return (
    <Root selected={selected}>
      {props.spritesheet && (
        <PngSequencer
          path={props.spritesheet}
          frameW={props.frame.width}
          frameH={props.frame.height}
          selected={selected}
          visible={visible}
        ></PngSequencer>
      )}
      <ContentLayer selected={selected} {...props}></ContentLayer>
    </Root>
  )
}

export default memo(Slide)
