import device_1 from './device_1.png'
import device_2 from './device_2.png'
import device_3 from './device_3.png'
import device_4 from './device_4.png'
import device_5 from './device_5.png'
import device_6 from './device_6.png'
import device_7 from './device_7.png'
import device_8 from './device_8.png'
import device_9 from './device_9.png'

const spritesheets = {
  device_1,
  device_2,
  device_3,
  device_4,
  device_5,
  device_6,
  device_7,
  device_8,
  device_9,
}

export default spritesheets
