import React, { useRef } from 'react'
import { animated } from '@react-spring/web'
import { useClickAway } from 'react-use'

import { ReactComponent as XIcon } from 'assets/uiIcons/times.svg'
import { ReactComponent as RightChevronIcon } from 'assets/uiIcons/chevron-right.svg'
import { useApp } from 'store/AppStore'

import { CloseButton, Root } from './Modal.styled.js'

const Modal = ({ style, variant, closeModal }) => {
  const { data, altLayout } = useApp()

  const contentRef = useRef()
  useClickAway(contentRef, closeModal)

  return (
    <Root
      style={style}
      altLayout={altLayout}
    >
      <div
        className="Content"
        ref={contentRef}
      >
        <div className="Inner">
          <div className="ContentWrapper">
            <h3>{data[variant].name}</h3>
            <p>{data[variant].description}</p>
          </div>
          <CloseButton onClick={closeModal}>
            {altLayout ? <RightChevronIcon></RightChevronIcon> : <XIcon></XIcon>}
          </CloseButton>
        </div>
      </div>
    </Root>
  )
}

export default animated(Modal)
