import styled, { css } from 'styled-components/macro'
import { colors } from 'styled/theme.styled'

export const Head = styled.div`
  position: relative;
  height: 80px;
  overflow: hidden;

  font-size: 16px;
  font-weight: 600;
  color: ${colors.purple_600};

  > .Inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .Icon {
      > svg {
        display: block;
        width: 24px;
        fill: currentColor;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
`

export const Body = styled.div`
  position: relative;
  width: ${({ altLayout }) => (altLayout ? '100%' : '330px')};
  height: calc(100vh - ${({ isShort, altLayout }) => (altLayout ? 84 : isShort ? 250 : 380)}px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > .Inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

export const Root = styled.div`
  position: absolute;
  background-color: ${colors.purple_300};
  z-index: 99;

  ${({ isShort }) =>
    isShort
      ? css`
          right: 12px;
          top: 12px;
        `
      : css`
          right: 24px;
          top: 24px;
        `};

  ${({ isPortrait }) =>
    isPortrait
      ? css`
          border-radius: 20px;
          left: 12px;
          right: 12px;

          ${Head} {
            height: 60px;
          }

          ${Body} {
            width: 100%;
          }
        `
      : css`
          border-radius: 40px;
        `};

  ${({ altLayout }) =>
    altLayout &&
    css`
      width: calc(var(--sidebar-width) - 24px);
      right: 12px;
      left: auto;
    `};
`

export const ProductFeaturesRoot = styled.div`
  position: relative;
  flex: 0 0 100%;
  padding-right: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .rc-scrollbars-thumb.rc-scrollbars-thumb-v {
    background-color: ${colors.purple_300} !important;
  }

  .Inner {
    flex: 0 999 100%;
    z-index: 1;
    padding: 12px 20px 12px 24px;
  }

  > .Footer {
    flex: 0 0 auto;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid ${colors.purple_300};

    .Icon {
      width: 14px;
      transition: transform 0.3s;
      transform: rotate(${({ chevronRotate }) => (chevronRotate ? 0 : 180)}deg);

      > svg {
        display: block;
        width: 100%;
        fill: ${colors.purple_500};
      }
    }
  }
`

export const FeatureItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      left: -24px;
      right: -20px;
      bottom: 0;
      height: 1px;
      background-color: ${colors.purple_300};
    }
  }

  &:not(:first-child) {
    padding-top: 40px;
  }

  > .Title {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: ${({ centerTitle }) => (centerTitle ? 'center' : 'left')};
    color: ${colors.purple_600};
    margin-bottom: 16px;
  }
`

export const FeatureBlock = styled.div`
  width: 100%;
  display: ${({ altLayout }) => (altLayout ? 'block' : 'flex')};
  font-size: ${({ hasIcon }) => (hasIcon ? 14 : 16)}px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.neutralsGrey};
    padding-bottom: 24px;
  }
  &:not(:first-child) {
    padding-top: 24px;
  }

  > .Icon {
    flex: 0 0 auto;
    width: 40px;
    height: auto;
    margin-right: 16px;
  }

  > .Text {
    color: ${colors.midGrey};
    line-height: 1.5;

    > .Subtitle {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      color: ${colors.midGrey};
      margin-bottom: 8px;
    }

    p {
      white-space: pre-line;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  > .Image {
    width: 100%;
    height: 160px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`
export const FeatureButton = styled.div`
  position: relative;
  flex: 0 0 auto;

  transition: all 0.3s;

  .Icon {
    position: relative;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.purple_500};
    z-index: 0;

    > svg {
      width: auto;
      height: 24px;
      overflow: visible;
      fill: currentColor;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${colors.purple_300};
      border-radius: 50%;
      z-index: -1;
      opacity: ${({ selected }) => (selected ? 1 : 0)};
      transition: opacity 0.3s;
    }
  }

  .Text {
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    white-space: pre;
    opacity: ${({ variant }) => (variant === 'button' ? 0 : 1)};
    transition: opacity 0.3s;
  }

  &:not(:last-child) {
    margin-right: ${({ variant }) => (variant === 'button' ? 48 : 80)}px;
  }

  &:hover {
    cursor: pointer;
  }
`
