import en from './en'
import de from './de'
import fr from './fr'

const languages = {
	en,
	de,
	fr,
}

export default languages
